.middle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
}

.logo-forge {
  width: 100vw;
  max-height: 25vw;
  font-family: courier;
  font-weight: 500;
  color: #FE1C41;
  font-size: 4rem;
  line-height: 1ex;
  text-align: center;
  white-space: nowrap;
}

.footer {
  position:absolute;
  bottom: 0px;
  left: 46vw;
  margin-bottom: 10px;
  
  display: flex;
  justify-content: center;
}


.logo-bottom{
  fill: #1d1e1f;
  stroke: rgba(255, 255, 255, 0.7);
  width: 40px;
  height: 40px;
  margin: 5px;
}

.fills{
  fill: rgba(255, 255, 255, 0.7);
}

.typewritter-text {
  animation: animated-text 3s steps(29, end) 1s 1 normal both,
    animated-cursor 650ms steps(29, end) infinite;
  border-right: solid 3px #FE1C41;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Source Code Pro', monospace;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 15px;
}

@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 520px;
  }
}

@keyframes animated-cursor {
  from {
    border-right-color: #FE1C41;
  }
  to {
    border-right-color: transparent;
  }
}
